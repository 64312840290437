.explore-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card {
  width: 30rem;
  height: 40rem;
  margin-bottom: 4rem;
  margin-right: 4rem;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);
  position: relative;
  background-color: $color-grey-light-2;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;

  &:hover {
    opacity: 1;
  }

  &:hover &__picture {
    filter: brightness(100%);
  }

  &__picture {
    width: 100%;
    height: 50%;
    border-radius: 3px;
    transition: all 0.4s ease;
    filter: brightness(50%);
    backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;

    @include respond(tab-land) {
      filter: brightness(100%);
    }
  }

  &__heading {
    position: absolute;
    color: white;
    font-size: 1.6em;
    top: 3rem;
    left: 2rem;
    opacity: 0.8;
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
    text-transform: uppercase;
    background-color: $color-grey-dark;
  }

  &__details {
    padding: 1.5rem;
    font-size: 1.4em;
    height: 19.5rem;
  }

  &__text {
    //border-bottom: 1px solid $color-grey-dark-2;
  }

  &__information {
    bottom: 1rem;
    position: absolute;
    display: flex;

    &-text {
      text-align: center;
      margin-left: 1rem;
    }

    &-detail {
      position: absolute;
      bottom: 1.5rem;
    }

    &-price {
      position: absolute;
      left: 3.5rem;
    }

    &-distance {
      position: absolute;
      left: 3rem;
    }
  }

  &__icon {
    text-align: center;
    height: 2.5rem;
    width: 2.5rem;
  }
}
