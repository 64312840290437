.comfort-photo {
  position: relative;

  width: 40%;
  background-position: center;
  box-shadow: 0 1.5rem 4rem rgba($color-black, 0.4);
  border-radius: 2px;
  background-repeat: no-repeat;

  &__photo--1 {
    background-image: url(../../img/HotelGullfoss/comfort-2.jpg);
  }

  &__photo--2 {
    background-image: url(../../img/HotelGullfoss/comfort-13.jpg);
    align-content: right;
  }

  &__photo--3 {
    background-image: url(../../img/HotelGullfoss/comfort-16.jpg);
  }
}

.comfort-photos {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
