.u-center-text { text-align: center !important; }
.u-right-text { text-align: right !important; }
.u-left-text { text-align: left !important; }

.u-margin-bottom-small { margin-bottom: 1.5rem !important; }
.u-margin-bottom-medium { margin-bottom: 4rem !important; }
.u-margin-bottom-big { margin-bottom: 8rem !important; }

.u-margin-top-small { margin-top: 3rem !important; }
.u-margin-top-medium { margin-top: 6rem !important; }
.u-margin-top-big { margin-top: 8rem !important; }
.u-margin-top-huge { margin-top: 10rem !important; }

.u-padding-top { padding-top: 5rem !important; }
.u-padding-sides-small { padding: 0 6rem !important; }
.u-padding-sides-large { padding: 0 12rem !important; }

.u-border-bottom-white { border-bottom: 1px solid $color-white !important; width: 80%; }
.u-border-bottom-grey { border-bottom: 1px solid $color-grey-dark-3 !important; width: 80%; }
.u-border-bottom-blue { border-bottom: 1px solid $color-primary-dark !important; width: 80%; }