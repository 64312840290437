.section-hero {
  height: 85vh;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)),
    url(../../img/gullfoss.jpeg);
  background-size: cover;
  background-position: top;
  position: relative;
  display: block;
}

.section-welcome {
  background-color: $color-grey-light-1;
  padding: 10em 6em;
  // margin-top: -20vh;
}

.section-rooms {
  border-top: $color-grey-dark-2;
  //background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
  //background-color: $color-grey-dark-3;
  background-color: $color-grey-dark-3;
  padding-top: 5em !important;
}

.section-explore {
  padding: 0 6rem 3rem 6rem;

  &__info {
    margin-top: 2rem;
    padding: 0 13rem;
    font-size: 1.5em;

    @include respond(phone) {
      padding: 0;
    }
  }
}

.section-location {
  border-top: $color-grey-dark-2;
  background-color: $color-grey-dark-3;
  height: 60rem;

  @include respond(phone) {
    height: 67rem;
  }

  @include respond(small-phone) {
    height: 75rem;
  }

  &__info {
    margin-top: 2rem;
    padding: 0 10rem;
    font-size: 1.5em;
    margin-bottom: 8rem;

    @include respond(tab-land) {
      margin-bottom: 4rem;
    }

    @include respond(big-desktop) {
      margin-bottom: 8rem;
    }

    @include respond(phone) {
      padding: 0 2rem;
      margin-bottom: 2rem;
    }
  }

  &__content {
    height: 22.5rem;
    width: 30rem;
    //background-color: $color-grey-dark;
    //text-align: center;
    display: block;
    margin: 0 auto;

    @include respond(small-phone) {
      margin: 0 3rem;
    }
  }

  &__image {
    position: absolute;

    &:hover {
      cursor: pointer;

      & + svg {
        opacity: 1;
      }
    }
  }

  &__icon {
    height: 4rem;
    width: 4rem;
    position: relative;
    opacity: 0.4;
    fill: $color-primary-dark-2;
    top: 47%;
    left: 39%;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    @include respond(tab-land) {
      top: 55%;
      left: 39%;
    }

    @include respond(tab-port) {
      top: 65%;
      left: 42%;
    }

    @include respond(phone) {
      top: 63%;
      left: 43%;
    }

    @include respond(big-desktop) {
      top: 37%;
      left: 33%;
    }
  }
}

.article-welcome {
}

.article-comfort {
  margin-top: 5em;
}

.section-menu {
  margin-top: 2rem !important;
  padding: 0 13rem;
  font-size: 1.5em;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;

  &__link {
    font-weight: 800;
    font-size: 1.6rem;
  }

  @include respond(tab-port) {
    display: block;
    padding: 0;
  }
}

.menu-gallery {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);

  grid-gap: 1.5rem;
  // padding-top: 3rem;

  @include respond(phone) {
    padding: 0;
  }

  @include respond(tab-land) {
    margin-top: 2rem;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 3px;
  }

  &__item {
    &--1 {
      grid-row: 1 / span 2;
      grid-column: 3 / span 2;
    }

    &--2 {
      grid-row: 1 / span 4;
      grid-column: 1 / span 2;
    }
  }
}
