.header {
  //width: 100vw;
  width: 100%;
  //height: 15rem;
  //height: 20vh;
  background-color: $color-white;
  position: sticky;
  z-index: 101;
  background-color: $color-grey-light-1;
  border-bottom: 1px solid $color-grey-dark-2;
  display: flex;
  flex: 0 0 40%;
  justify-content: space-between;
  align-items: center;
  backface-visibility: hidden;
  top: 0;

  @include respond(tab-port) {
    padding: 1rem 0;
  }

  /*@include respond(tab-port) {
        margin-top: -20rem;
    }

    @include respond(big-desktop) {
        margin-top: -12rem;
    }*/

  &__logo-box {
    //position: fixed;
    //position: inherit;
    top: 1.5rem;
    left: 12rem;
    z-index: 2000;
    //margin-left: 3rem;
    margin: 1rem 0 1rem 3rem;

    @include respond(phone) {
      margin-left: 1rem;
    }
  }

  &__logo {
    height: 12rem;
    //height: 25vh;
    //margin-top: 3rem;
    cursor: pointer;
    border: none;

    @include respond(phone) {
      height: 8rem;
      margin-right: 3rem;
    }
  }

  &__text-box {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    @include respond(phone) {
      top: 50%;
    }
  }

  &__navigation {
    //position: absolute;
    //position: inherit;
    //top: 50%;
    //left: 30%;
  }

  &__navigation-bar {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__navigation-item {
    display: inline;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    @include respond(tab-port) {
      display: block;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  &__navigation-button {
    display: inline;
    cursor: pointer;
    background-color: white;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    @include respond(tab-port) {
      display: block;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  &__navigation-link {
    font-family: 'Poppins', sans-serif;
    color: black;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.4em;
    letter-spacing: 0.125rem;

    &:hover {
      color: $color-primary;
    }
  }

  &__btn {
    &,
    &:link,
    &:visited {
      text-transform: uppercase;
      text-decoration: none;
      padding: 1.5rem 4rem;
      //display: inline-block;
      border-radius: 10rem;
      transition: all 0.2s;
      //position: relative;
      font-size: $default-font-size;

      //Change for the <button> element
      border: none;
      cursor: pointer;

      @include respond(small-phone) {
        margin-right: 1rem;
      }
    }

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 1rem 2rem rgba($color-black, 0.2);

      &::after {
        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0;
      }
    }

    &:active,
    &:focus {
      outline: none;
      transform: translateY(-1px);
      box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
    }

    &--blue {
      background-color: $color-primary;
      color: $color-white;

      &::after {
        background-color: $color-primary;
      }
    }

    &--white {
      background-color: $color-white;
      color: $color-primary;

      &::after {
        background-color: $color-white;
      }
    }

    &::after {
      content: '';
      //display: inline-block;
      height: 100%;
      width: 100%;
      border-radius: 10rem;
      //position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all 0.4s;
    }

    &--animated {
      animation: moveInBottom 0.5s ease-out 0.75s;
      animation-fill-mode: backwards;
    }

    &--order {
      //position: fixed;
      top: 4.8rem;
      right: 6rem;
      z-index: 1500;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 3rem;

    @include respond(tab-port) {
      margin-left: 3rem;
    }
  }

  &__flag {
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    border: none;

    // & img {
    //   max-width: 3rem;
    // }

    &-selected {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
      }
    }

    &-icon {
      border-radius: 8px;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
