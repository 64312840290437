.rooms {
  box-sizing: border-box;
  margin: 0 6rem;

  @include respond(phone) {
    margin: 0;
  }

  &:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
  }

  &:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__img {
    width: 55rem;
    height: 30rem;
    overflow: hidden;
    border-radius: 3px;
  }

  &__photo {
    width: 55rem;
    height: 30rem;
    //box-shadow: 0 2.5rem 4rem rgba($color-black, .5);
    transition: all 0.2s ease-in-out;
    backface-visibility: hidden;
    border-radius: 3px;

    &--p1 {
    }

    &--p2 {
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &__content {
    width: 50%;
    font-size: 1.3em;
    font-weight: 400;

    @include respond(phone) {
      width: 30rem;
    }
  }

  &__room--1 {
  }

  &__room--2 {
    margin-top: 5rem;
    padding-bottom: 5rem;
  }

  &__text {
    margin-bottom: 3rem;
    font-size: 1.2em;
    right: 0;
  }

  &-gallery {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(7, 5vw);
    grid-gap: 1.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    &__img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 3px;
    }

    &__item {
      &--1 {
        grid-row: 1 / span 4;
        grid-column: 1 / span 6;
      }

      &--2 {
        grid-row: 5 / span 3;
        grid-column: 1 / span 3;
      }

      &--3 {
        grid-row: 5 / span 3;
        grid-column: 4 / span 3;
      }

      &--4 {
        grid-row: 4 / span 3;
        grid-column: 5 / span 2;
      }

      &--5 {
        grid-row: 5 / span 3;
        grid-column: 7 / span 2;
      }

      &--6 {
        grid-row: 6 / span 2;
        grid-column: 4 / span 3;
      }

      &--7 {
        grid-row: 1 / span 5;
        grid-column: 5 / span 2;
      }

      &--8 {
        grid-row: 5 / span 3;
        grid-column: 1 / span 3;
      }
    }
  }
}
