*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  padding: 0px !important;

  @include respond(tab-land) {
    font-size: 56.25%;
  }

  @include respond(tab-port) {
    font-size: 50%;
  }

  @include respond(big-desktop) {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

h3 {
  font-weight: 600;
  line-height: 1.2;
  margin: 10px 0;
}

p {
  margin: 10px 0;
}
