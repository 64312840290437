.footer {
  height: 30rem;
  background-color: $color-grey-dark;
  text-align: center;
  border-top: 1px solid white;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 7rem;

  &__info {
    width: 10rem;
    height: 10rem;

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__icon {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    outline-offset: 0.7rem;
    margin-bottom: 1rem;

    &-phone {
      fill: $color-primary-dark;
      outline: 1px solid $color-primary-dark;

      &:hover {
        outline: 2.5px solid $color-primary-dark;
      }
    }

    &-location {
      fill: $color-secondary-light;
      outline: 1px solid $color-secondary-light;

      &:hover {
        outline: 2.5px solid $color-secondary-light;
      }
    }

    &-mail {
      fill: $color-secondary-dark;
      outline: 1px solid $color-secondary-dark;

      &:hover {
        outline: 2.5px solid $color-secondary-dark;
      }
    }

    &-facebook {
      &:hover {
        opacity: 0.4;
      }
    }

    &-twitter {
      &:hover {
        opacity: 0.4;
      }
    }

    &-tripadvisor {
      &:hover {
        opacity: 0.4;
      }
    }
  }

  &__text-primary {
    font-size: 1.5em;
    font-weight: 600;
    color: black;
  }

  &__text-secondary {
    font-size: 1.2em;
    font-weight: 400;
    color: black;
  }
}

.footer-pr {
  display: flex;
  justify-content: center;
  align-items: center;

  & :not(:last-child) {
    margin-right: 3rem;
  }

  &__facebook-button {
    height: 4rem;
    width: 4rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__twitter-button {
    height: 4rem;
    width: 4rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__tripadvisor-button {
    height: 4rem;
    width: 4rem;

    &:hover {
      cursor: pointer;
    }
  }
}
