.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(7, 5vw);
  grid-gap: 1.5rem;
  padding-top: 3rem;

  &__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 3px;
  }

  &__item {
    &--1 {
      grid-row: 1 / span 4;
      grid-column: 3 / span 2;
    }

    &--2 {
      grid-row: 1 / span 4;
      grid-column: 1 / span 2;
    }

    &--4 {
      grid-row: 1 / span 4;
      grid-column: 7 / span 2;
    }

    &--5 {
      grid-row: 5 / span 3;
      grid-column: 7 / span 2;
    }

    &--6 {
      grid-row: 5 / span 3;
      grid-column: 4 / span 3;
    }

    &--7 {
      grid-row: 1 / span 4;
      grid-column: 3 / span 4;
    }

    &--8 {
      grid-row: 5 / span 3;
      grid-column: 1 / span 3;
    }
  }
}
