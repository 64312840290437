body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: $color-grey-dark;
}

.heading-primary {
  color: $color-white;
  text-transform: uppercase;

  backface-visibility: hidden;

  &--main {
    display: block;
    font-size: 7.5rem;
    font-weight: 400;
    letter-spacing: 0.4rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    margin-bottom: 40vh;

    @include respond(phone) {
      letter-spacing: 0.3rem;
      font-size: 5rem;
    }
  }

  &--sub {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1rem;
    animation: moveInRight 1s ease-out;

    @include respond(small-phone) {
      margin: 0 2rem;
    }

    @include respond(phone) {
      letter-spacing: 0.5rem;
    }
  }
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  background-image: linear-gradient(
    to right,
    $color-primary-light,
    $color-primary-dark-2
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;

  @include respond(tab-land) {
    font-size: 3rem;
  }

  @include respond(tab-port) {
    font-size: 2.5rem;
  }
}

.heading-tertiary {
  font-size: $default-font-size;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-primary;
}

.paragraph {
  font-size: $default-font-size;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}
