// COLORS
$color-primary: #7aa3a9;
$color-primary-light: #c7d1d2;
$color-primary-dark: #7aa3a9;
$color-primary-dark-2: #306068;

$color-secondary-light: #d49c02;
$color-secondary-dark: #ce6329;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #5643fa;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

// FONT
$default-font-size: 1.7rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;
