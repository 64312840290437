.modal {
  font-size: 1.6rem;
  background-color: #7aa3a9;
  border-radius: 18px;
  color: black;
  padding: 3rem;

  @include respond(phone) {
    padding: 1rem;
  }

  &__header {
    width: 100%;
    font-size: 28px;
    padding: 5px;
    border-bottom: 1px solid #ffffff;
    text-transform: uppercase;
    padding-bottom: 2rem;
    text-align: center;

    &-title {
      text-align: center;

      @include respond(phone) {
        letter-spacing: 1px;
      }
    }

    &-btn {
      position: absolute;
      left: 3rem;
      padding: 0.75rem 2rem !important;
      font-size: 1.3rem !important;

      @include respond(phone) {
        position: relative;
        display: block;
        letter-spacing: 1px;
        left: 0rem;
        width: 15rem;
        margin: auto !important;
      }
      @include respond(small-phone) {
        margin: 0;
      }
    }

    @include respond(phone) {
      font-size: 18px;
    }
  }

  &__content {
    width: 100%;
    padding: 10px 5px;
    margin-top: 2rem;

    &-image {
      border-radius: 10px;
      margin-left: 2rem;

      @include respond(tab-port) {
        display: none;
      }
    }
  }

  &__actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }

  &__cancel-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    background-color: black;
    z-index: 25;
    color: white;

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
}

.popup-content {
  margin: 5rem auto;
  background-color: #7aa3a9;
  border-radius: 18px;
  // position: fixed;
  width: 50%;
  padding: 5px;
  max-height: 100vh;
  overflow-x: auto;

  @include respond(tab-port) {
    width: 75%;
  }

  @include respond(phone) {
    width: 80%;
  }
}

.popup-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.ReactModal__Body--open {
  padding-right: 0px !important;
}

#root body {
  padding-right: 0px !important;
}
